import { Injectable } from '@angular/core';

import { Capsule, Space } from '../models';

import { CapsuleService } from '../clients/capsule/capsule.service';
import { DomainService } from '../clients/domain/domain.service';
import { SpaceService } from '../clients/space/space.service';
import { DataCapsuleService } from '../clients/capsule/data-capsule.service';

@Injectable({
  providedIn: 'root',
})
export class CapsuleUpdatesService {
  constructor(
    private capsuleService: CapsuleService,
    private domainService: DomainService,
    private spaceService: SpaceService,
    private dataCapsuleService: DataCapsuleService
  ) { }

  pHandlers = {
    onCapsuleDeploymentChanged: [],
    onCapsuleDetailsChanged: [],
    onCapsulePushesChanged: [],
    onCapsuleLogsChanged: [],
    onCapsuleMetricsChanged: [],
    onCapsuleRepoChanged: [],
    onSpaceChanged: [],
    onDomainsChanged: [],
    onCapsuleChanged: [],
    onBuildsChanged: [],
    onDataCapsuleChanged: [],
    onChanged: [],
    onError: [],
  };

  private pCapsule: Capsule;
  private pSpace: Space;

  private pCapsuleLogsPollInterval = 10000;
  private pLongBuildPollInterval = 10000;
  private pBuildPollInterval = 10000;
  private pDataCapsulePollInterval = 10000;
  private pCapsuleLogsPollingEvent = null;
  private pLongBuildPollEvent = null;
  private pBuildPollEvent = null;
  private pDataCapsulePollEvent = null;

  public metricsInterval = 'last5Minutes';
  public logHistorySize = 50;
  public logHistoryPage = 0;

  async setSpaceAndCapsule(spaceId: string, capsuleId: string): Promise<void> {
    await this.invalidateAll(spaceId, capsuleId);
    if (this.pLongBuildPollEvent === null) {
      this.pLongBuildPollEvent = setInterval(() => {
        this.invalidateBuilds();
      }, this.pLongBuildPollInterval);
    }
  }

  async invalidateAll(spaceId: string, capsuleId: string): Promise<void> {
    await this.invalidateSpace(spaceId);
    await this.invalidateCapsule(capsuleId);
    // this.invalidateBuilds();
    await this.invalidateCapsuleLogsAndMetrics();
    // this.invalidateDeployment(capsuleId);
    // this.invalidateDomain(capsuleId);
    // this.invalidateRepo(capsuleId);
    await this.invalidateDataCapsule();
  }

  private async invalidateSpace(spaceId?): Promise<void> {
    try {
      const tmpSpaceId = spaceId || this.pSpace.id;
      const space = await this.spaceService.getSpaceById(tmpSpaceId);
      this.pSpace = space;
      this.pHandlers.onSpaceChanged.forEach((element) => {
        element(space);
      });
      this._propagateUpdates('onSpaceChanged');
    } catch (err) {
      this._propagateError(err, 'onSpaceChanged');
    }
  }

  async invalidateCapsule(capsuleId?): Promise<void> {
    try {
      const tmpCapsuleId = capsuleId || this.pCapsule.id;
      const capsule = await this.capsuleService.getCapsuleById(
        this.pSpace,
        tmpCapsuleId
      );
      this.pCapsule = capsule;
      this.pHandlers.onCapsuleChanged.forEach((element) => {
        element(capsule);
      });
      this._propagateUpdates('onCapsuleChanged');
    } catch (err) {
      this._propagateError(err, 'onCapsuleChanged');
    }
  }

  async invalidateDomain(capsuleId: string): Promise<void> {
    try {
      const domains = await this.domainService.getDomains(
        this.pSpace,
        capsuleId
      );
      this.pHandlers.onDomainsChanged.forEach((element) => {
        element(domains);
      });
      this._propagateUpdates('onDomainsChanged');
    } catch (err) {
      this._propagateError(err, 'onDomainsChanged');
    }
  }

  setCapsuleMetricsInterval(interval): void {
    this.metricsInterval = interval;
  }

  setBuildPollingEnabled(enabled): void {
    if (enabled && this.pBuildPollEvent === null) {
      this.pBuildPollEvent = setInterval(() => {
        this.invalidateBuilds();
      }, this.pBuildPollInterval);
    } else if (!enabled) {
      clearInterval(this.pBuildPollEvent);
      this.pBuildPollEvent = null;
    }
  }

  setDataCapsulePollingEnabled(enabled): void {
    if (enabled && this.pDataCapsulePollEvent === null) {
      this.pDataCapsulePollEvent = setInterval(() => {
        this.invalidateDataCapsule();
      }, this.pDataCapsulePollInterval);
    } else if (!enabled) {
      clearInterval(this.pDataCapsulePollEvent);
      this.pDataCapsulePollEvent = null;
    }
  }

  async invalidateBuilds(): Promise<void> {
    try {
      const builds = await this.capsuleService.getBuildsByCapsuleId(
        this.pSpace,
        this.pCapsule.id
      );
      this.pHandlers.onBuildsChanged.forEach((element) => {
        element(builds);
      });
      this._propagateUpdates('onBuildsChanged');
    } catch (err) {
      this._propagateError(err, 'onBuildsChanged');
    }
  }

  async invalidateRepo(capsuleId: string): Promise<void> {
    try {
      const capsuleRepo = await this.capsuleService.getCapsuleRepo(
        this.pSpace,
        capsuleId
      );
      this.pHandlers.onCapsuleRepoChanged.forEach((element) => {
        element(capsuleRepo);
      });
      this._propagateUpdates('onCapsuleRepoChanged');
    } catch (err) {
      this._propagateError(err, 'onCapsuleRepoChanged');
    }
  }

  async invalidateDeployment(capsuleId: string): Promise<void> {
    try {
      const deployment = await this.capsuleService.getCurrentDeployment(
        this.pSpace.cluster.clusterApiEndpoint,
        capsuleId
      );
      this.pHandlers.onCapsuleDeploymentChanged.forEach((element) => {
        element(deployment);
      });
      this._propagateUpdates('onCapsuleDeploymentChanged');
    } catch (err) {
      this._propagateError(err, 'onCapsuleDeploymentChanged');
    }
  }

  setCapsuleLogsPollingEnabled(enabled): void {
    if (enabled && this.pCapsuleLogsPollingEvent === null) {
      this.pCapsuleLogsPollingEvent = setInterval(async () => {
        await this.invalidateCapsuleLogsAndMetrics();
      }, this.pCapsuleLogsPollInterval);
    } else if (!enabled) {
      clearInterval(this.pCapsuleLogsPollingEvent);
      this.pCapsuleLogsPollingEvent = null;
    }
  }

  async invalidateCapsuleLogsAndMetrics(): Promise<void> {
    const logsProm = this.invalidateCapsuleLogs();
    const metricsProm = this.invalidateCapsuleMetrics();
    await Promise.all([
      logsProm, metricsProm
    ]);
  }

  async invalidateCapsuleLogs(): Promise<void> {
    try {
      // if (this.pCapsule.type !== 'data') {
      const capsuleLogsResponse = await this.capsuleService.getCapsuleLogs(
        this.pSpace,
        this.pCapsule.id,
        this.logHistorySize,
        this.logHistoryPage
      ) as any;
      this.pHandlers.onCapsuleLogsChanged.forEach((element) => {
        element(capsuleLogsResponse.data);
      });
      this._propagateUpdates('onCapsuleLogsChanged');
      // }
    } catch (err) {
      this._propagateError(err, 'onCapsuleLogsChanged');
    }
  }

  async invalidateCapsuleMetrics(): Promise<void> {
    try {
      const capsuleMetrics = await this.capsuleService.getCapsuleMetrics(
        this.pSpace,
        this.pCapsule.id,
        this.metricsInterval
      );
      this.pHandlers.onCapsuleMetricsChanged.forEach((element) => {
        element(capsuleMetrics);
      });
      this._propagateUpdates('onCapsuleMetricsChanged');
    } catch (err) {
      this._propagateError(err, 'onCapsuleMetricsChanged');
    }
  }

  async invalidateDataCapsule(): Promise<void> {
    if (this.pCapsule?.type === 'data') {
      try {
        const dataCapsule = await this.dataCapsuleService.getDataCapsuleStatus(
          this.pSpace, this.pCapsule.id
        );
        this.pHandlers.onDataCapsuleChanged.forEach((element) => {
          element(dataCapsule);
        });
        this._propagateUpdates('onDataCapsuleStatusChanged');
      } catch (err) {
        this._propagateError(err, 'onDataCapsuleStatusChanged');
      }
    }
  }

  stopAllPolling(): void {
    this.setBuildPollingEnabled(false);
    this.setCapsuleLogsPollingEnabled(false);
    this.setDataCapsulePollingEnabled(false);
  }

  resetHandlers(): void {
    this.pHandlers = {
      onChanged: [],
      onCapsuleChanged: [],
      onCapsuleDetailsChanged: [],
      onBuildsChanged: [],
      onCapsuleDeploymentChanged: [],
      onCapsulePushesChanged: [],
      onCapsuleLogsChanged: [],
      onCapsuleMetricsChanged: [],
      onCapsuleRepoChanged: [],
      onDomainsChanged: [],
      onSpaceChanged: [],
      onDataCapsuleChanged: [],
      onError: [],
    };
  }

  destroy(): void {
    this.resetHandlers();
    this.pCapsule = null;
    this.pSpace = null;
    this.stopAllPolling();
  }

  _propagateError(error, event): void {
    this.pHandlers.onError.forEach((element) => {
      element(error, event);
    });
  }

  _propagateUpdates(event): void {
    this.pHandlers.onChanged.forEach((element) => {
      element(event);
    });
  }

  onChanged(handler): void {
    this.pHandlers.onChanged.push(handler);
  }

  onCapsuleDetailsChanged(handler): void {
    this.pHandlers.onCapsuleDetailsChanged.push(handler);
  }

  onCapsuleChanged(handler): void {
    this.pHandlers.onCapsuleChanged.push(handler);
  }

  onBuildsChanged(handler): void {
    this.pHandlers.onBuildsChanged.push(handler);
  }

  onCapsuleDeploymentChanged(handler): void {
    this.pHandlers.onCapsuleDeploymentChanged.push(handler);
  }

  onCapsulePushesChanged(handler): void {
    this.pHandlers.onCapsulePushesChanged.push(handler);
  }

  onDomainsChanged(handler): void {
    this.pHandlers.onDomainsChanged.push(handler);
  }

  onCapsuleRepoChanged(handler): void {
    this.pHandlers.onCapsuleRepoChanged.push(handler);
  }

  onCapsuleLogsChanged(handler): void {
    this.pHandlers.onCapsuleLogsChanged.push(handler);
  }

  onCapsuleMetricsChanged(handler): void {
    this.pHandlers.onCapsuleMetricsChanged.push(handler);
  }

  onSpaceChanged(handler): void {
    this.pHandlers.onSpaceChanged.push(handler);
  }

  onDataCapsuleChanged(handler): void {
    this.pHandlers.onDataCapsuleChanged.push(handler);
  }

  onError(handler): void {
    this.pHandlers.onError.push(handler);
  }
}
