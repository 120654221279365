import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, mergeMap, from } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  concatMap,
} from 'rxjs/operators';
import moment from 'moment';

import * as CapsuleActions from './capsule.actions';
import * as SpaceActions from '../space/space.actions';
import {
  Build,
  Capsule,
  CapsuleCostsResponse,
  Space,
} from '../../models';
import { APIResponse } from '../../api';
import { ConfigDto } from '../../models/config-dto';
import { RepoService } from '../../clients/repo/repo.service';
import { capsuleIngressBasicAuth } from './capsule.actions';

export interface ClusterNamespaceKeys {
  clusterUrl: string;
  namespaceKeys: string[];
}

@Injectable()
export class CapsuleEffects {
  constructor(
    private actions$: Actions<any>,
    private httpClient: HttpClient,
    private repoService: RepoService
  ) {}

  deployCapsuleBuild$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setDeployBuild),
      switchMap((action) => {
        return this.httpClient
          .post<any>(
            `${action.space.cluster.clusterApiEndpoint}/capsules/${action.capsule.id}/deployments`,
            { buildId: action.build.id }
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.setDeployBuildSuccess({
                space: action.space,
                capsule: action.capsule,
                build: apiResponse,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.setDeployBuildFailed({ error }))
            )
          );
      })
    )
  );

  // fetch capsule
  fetchCapsule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsule, CapsuleActions.capsuleCreateSuccess),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;

        return this.httpClient
          .get<Capsule>(`${clusterApiEndpoint}/capsule/${action.capsuleId}`)
          .pipe(
            map((capsule) =>
              CapsuleActions.fetchCapsuleSuccess({
                space: action.space,
                capsule: capsule,
              })
            ),
            catchError((error) => of(CapsuleActions.fetchCapsuleFailed(error)))
          );
      })
    )
  );

  // fetch space capsules
  fetchSpaceCapsules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchSpaceCapsules),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const namespaceKey = action.space.namespaceKey;

        return this.httpClient
          .get<APIResponse<Capsule[]>>(
            `${clusterApiEndpoint}/namespaces/${namespaceKey}/capsules`
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.fetchCapsulesSuccess({
                capsules: apiResponse['capsules'] || [],
              })
            ),
            catchError((error) => of(CapsuleActions.fetchCapsulesFailed(error)))
          );
      })
    )
  );

  // fetch spaces capsules
  fetchSpacesCapsules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SpaceActions.initialFetchSpacesSuccess,
        CapsuleActions.fetchSpacesCapsules
      ),
      switchMap(({ spaces }) => {
        const clusterNamespaceKeys: ClusterNamespaceKeys[] = [];

        let clusterEndpoints = spaces.map((x) => x.cluster.clusterApiEndpoint);
        clusterEndpoints = [...new Set(clusterEndpoints)];

        for (const endpoint of clusterEndpoints) {
          clusterNamespaceKeys.push({
            clusterUrl: endpoint,
            namespaceKeys: spaces
              .filter((x) => x.cluster.clusterApiEndpoint === endpoint)
              .map((x) => x.namespaceKey),
          });
        }

        return from(clusterNamespaceKeys);
      }),
      mergeMap((clusterNamespaces: ClusterNamespaceKeys) => {
        const clusterApiEndpoint = clusterNamespaces.clusterUrl;
        const namespaces = JSON.stringify({
          namespaceKeys: clusterNamespaces.namespaceKeys,
        });
        return this.httpClient
          .get<APIResponse<Capsule[]>>(
            `${clusterApiEndpoint}/namespaces/capsules?namespaces=${namespaces}`
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.fetchCapsulesSuccess({
                capsules: apiResponse['capsules'] || [],
              })
            ),
            catchError((error) => of(CapsuleActions.fetchCapsulesFailed(error)))
          );
      })
    )
  );

  // fetch capsule usage
  fetchCapsuleUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsuleUsage),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const nameSpaceKey = action.space.namespaceKey;
        const capsuleId = action.capsule.id;
        const billingPeriod = this.formatBillingPeriod(action['billingPeriod']);

        const query = `?billing_period=${billingPeriod}&namespace_key=${nameSpaceKey}&capsule_id=${capsuleId}`;

        return this.httpClient
          .get<CapsuleCostsResponse>(`${clusterApiEndpoint}/costs${query}`)
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.fetchCapsuleUsageSuccess({
                capsuleUsage: apiResponse,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.fetchCapsuleUsageFailed(error))
            )
          );
      })
    )
  );

  // capsule branch
  setCapsuleRepo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setCapsuleRepo),
      concatMap(({ space, capsuleId, branch, repo }) => {
        const cluster = space.cluster;
        const clusterApiEndpoint = cluster.clusterApiEndpoint;
        const payloadRepo = this.repoService.getCapsuleApiRepoPayload({
          repo,
          branch,
          cluster,
        });

        return this.httpClient
          .put<any>(
            `${clusterApiEndpoint}/capsules/${capsuleId}/repo`,
            payloadRepo
          )
          .pipe(
            map((apiResponse) => {
              const repo = apiResponse;
              return CapsuleActions.setCapsuleRepoSuccess({
                space,
                capsuleId,
                repo,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.setCapsuleRepoFailed({ error }))
            )
          );
      })
    )
  );

  // set capsule auto build
  setCapsuleAutoBuild$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.updateCapsuleAutoBuild),
      concatMap((action) => {
        let capsuleType = 'backend-capsule';
        if (action.capsule.type === 'deploy') {
          capsuleType = 'frontend-capsule';
        }

        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .patch<any>(
            `${clusterApiEndpoint}/${capsuleType}/${capsuleId}/should-auto-build`,
            { shouldAutoBuild: action.capsule.shouldAutoBuild }
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.updateCapsuleAutoBuildSuccess({
                capsule: apiResponse,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.updateCapsuleAutoBuildFailed(error))
            )
          );
      })
    )
  );

  // update capsule products
  updateCapsuleProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.updateCapsuleProducts),

      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .patch(
            `${clusterApiEndpoint}/capsules/${capsuleId}/products`,
            action.products
          )
          .pipe(
            map((apiResponse: Capsule) => {
              return CapsuleActions.updateCapsuleProductsSuccess({
                space: action.space,
                capsule: apiResponse,
                products: action.products,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.updateCapsuleProductsFailed({ error }))
            )
          );
      })
    )
  );

  // get capsule config
  fetchCapsuleConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsuleConfig),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .get<{ configs: ConfigDto }>(
            `${clusterApiEndpoint}/capsules/${capsuleId}/configs`
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.fetchCapsuleConfigSuccess({
                config: apiResponse.configs,
                capsule: action.capsule,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.fetchCapsuleConfigFailed(error))
            )
          );
      })
    )
  );

  // update capsule config
  updateCapsuleConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.updateCapsuleConfig),
      concatMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .put<APIResponse<Space>>(
            `${clusterApiEndpoint}/capsules/${capsuleId}/configs`,
            action.config
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.updateCapsuleConfigSuccess({
                config: apiResponse['configs'],
                capsule: action.capsule,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.updateCapsuleConfigFailed(error))
            )
          );
      })
    )
  );

  // update capsule manifest
  updateCapsuleManifest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.updateCapsuleManifest),
      concatMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const namespaceKey = action.space.namespaceKey;
        const capsuleId = action.capsule.id;
        const manifest = action.manifest;

        return this.httpClient
          .patch<any>(
            `${clusterApiEndpoint}/namespaces/${namespaceKey}/capsule/${capsuleId}/manifest`,
            manifest
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.updateCapsuleManifestSuccess({
                capsule: apiResponse,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.updateCapsuleManifestFailed(error))
            )
          );
      })
    )
  );

  // update capsule description
  updateCapsuleDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.updateCapsuleDescription),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const namespaceKey = action.space.namespaceKey;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .patch(
            `${clusterApiEndpoint}/namespaces/${namespaceKey}/capsule/${capsuleId}`,
            { description: action.description }
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.updateCapsuleDescriptionSuccess({
                space: action.space,
                capsule: { ...action.capsule, ...apiResponse },
              });
            }),
            catchError((error) =>
              of(CapsuleActions.updateCapsuleDescriptionFailed(error))
            )
          );
      })
    )
  );

  removeCapsule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.removeCapsule),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const namespaceKey = action.space.namespaceKey;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .delete<any>(
            `${clusterApiEndpoint}/namespaces/${namespaceKey}/capsules/${capsuleId}`
          )
          .pipe(
            map(() =>
              CapsuleActions.removeCapsuleSuccess({
                capsule: action.capsule,
                space: action.space,
              })
            ),
            catchError((error) => of(CapsuleActions.removeCapsuleFailed(error)))
          );
      })
    )
  );

  fetchCapsuleBuilds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CapsuleActions.fetchCapsuleBuilds,
        CapsuleActions.setDeployBuildSuccess
      ),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .get<{ builds: Build[] }>(
            `${clusterApiEndpoint}/capsules/${capsuleId}/builds`
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.fetchCapsuleBuildsSuccess({
                builds: apiResponse.builds || [],
                capsule: action.capsule,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.fetchCapsuleBuildsFailed({ error }))
            )
          );
      })
    )
  );

  updateMysqlCapsuleConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.mysqlCapsuleConfig.updateMysqlConfig),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;
        return this.httpClient
          .post<any>(
            `${clusterApiEndpoint}/data-capsule/${capsuleId}/mysql/options`,
            action.options
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.mysqlCapsuleConfig.updateMysqlConfigSuccess(
                {
                  capsule: apiResponse,
                  space: action.space,
                }
              );
            }),
            catchError((error) =>
              of(
                CapsuleActions.mysqlCapsuleConfig.updateMysqlConfigFailed({
                  error,
                })
              )
            )
          );
      })
    )
  );

  enableCapsuleIngress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setCapsuleIngressEnabled),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsuleId;

        return this.httpClient
          .patch<any>(`${clusterApiEndpoint}/capsule/${capsuleId}/ingress`, {
            enabled: action.enabled,
          })
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.setCapsuleIngressEnabledSuccess({
                capsule: apiResponse,
                space: action.space,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.setCapsuleIngressEnabledFailed({ error }))
            )
          );
      })
    )
  );

  enableCapsuleWebDav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setCapsuleWebDavEnabled),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsuleId;
        const url = `${clusterApiEndpoint}/data-capsule/${capsuleId}/webdav/enabled`;

        return this.httpClient.post<any>(url, { enabled: action.enabled }).pipe(
          map((apiResponse) => {
            return CapsuleActions.setCapsuleWebDavEnabledSuccess({
              capsule: apiResponse,
            });
          }),
          catchError((error) =>
            of(CapsuleActions.setCapsuleWebDavEnabledFailed({ error }))
          )
        );
      })
    )
  );

  disableCapsuleIngressBasicAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(capsuleIngressBasicAuth.disableBasicAuthOnIngress),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsuleId;

        return this.httpClient
          .patch<any>(
            `${clusterApiEndpoint}/capsule/${capsuleId}/ingress/basic-auth`,
            {
              enabled: false,
            }
          )
          .pipe(
            map((apiResponse) => {
              return capsuleIngressBasicAuth.disableBasicAuthOnIngressSuccess({
                capsule: apiResponse,
                space: action.space,
              });
            }),
            catchError((error) =>
              of(
                capsuleIngressBasicAuth.disableBasicAuthOnIngressFailed({
                  error,
                })
              )
            )
          );
      })
    )
  );
  enableCapsuleIngressBasicAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(capsuleIngressBasicAuth.enableBasicAuthOnIngress),
      switchMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsuleId;

        return this.httpClient
          .patch<any>(
            `${clusterApiEndpoint}/capsule/${capsuleId}/ingress/basic-auth`,
            {
              enabled: true,
            }
          )
          .pipe(
            map((apiResponse) => {
              return capsuleIngressBasicAuth.enableBasicAuthOnIngressSuccess({
                capsule: apiResponse,
                space: action.space,
              });
            }),
            catchError((error) =>
              of(
                capsuleIngressBasicAuth.disableBasicAuthOnIngressFailed({
                  error,
                })
              )
            )
          );
      })
    )
  );

  private formatBillingPeriod(billingPeriod?: Date): string {
    if (billingPeriod) {
      return moment(billingPeriod).utc().format('YYYY-MM');
    } else {
      return moment().utc().format('YYYY-MM');
    }
  }
}
