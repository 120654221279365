import { Domain } from '../../models/domain';
import { createAction, props } from '@ngrx/store';
import { Capsule, Space } from '../../models';

// fetch Domains Action
export const fetchDomains = createAction('[Domain API] Fetch Domains');
export const fetchDomainsSuccess = createAction(
  '[Domain API] Fetch Domains Success',
  props<{ domains: Domain[] }>()
);
export const fetchDomainsFailed = createAction(
  '[Domain API] Fetch Domains Failed',
  props<{ error: Error }>()
);

// fetch capsule domains
export const fetchCapsuleDomains = createAction(
  '[Domain API] Fetch Capsule Domains',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleDomainsSuccess = createAction(
  '[Domain API] Fetch Capsule Domains Success',
  props<{
    capsuleId: string;
    domains: Domain[];
    ipAddress: string;
    hostName: string;
  }>()
);
export const fetchCapsuleDomainsFailed = createAction(
  '[Domain API] Fetch Capsule Domains Failed',
  props<{ error: Error }>()
);

// add domain action
export const addDomain = createAction(
  '[Domain API] Add Domain Initiated',
  props<{ space: Space; capsule: Capsule; domain: { hostname: string } }>()
);
export const addDomainSuccess = createAction(
  '[Domain API] Add Domain Success',
  props<{ domain: Domain }>()
);
export const addDomainFailed = createAction(
  '[Domain API] Add Domain Failed',
  props<{ error: Error }>()
);

// remove capsule domain
export const removeCapsuleDomain = createAction(
  '[Domain API] Remove Capsule Domain',
  props<{ domain: Domain; space: Space; capsule: Capsule }>()
);
export const removeCapsuleDomainSuccess = createAction(
  '[Domain API] Remove Capsule Domain Success',
  props<{ domain: Domain; space: Space; capsule: Capsule }>()
);
export const removeCapsuleDomainFailed = createAction(
  '[Domain API] Remove Capsule Domain Failed',
  props<{ error: Error }>()
);

// renew domain certificate
export const renewDomainCertificate = createAction(
  '[Domain API] Renew Capsule Domain Certificate',
  props<{ domain: Domain; space: Space; }>()
);
export const renewDomainCertificateSuccess = createAction(
  '[Domain API] Renew Capsule Domain Certificate Success',
  props<{ domain: Domain; space: Space }>()
);
export const renewDomainCertificateFailed = createAction(
  '[Domain API] Renew Capsule Domain Certificate Failed',
  props<{ error: Error }>()
);
