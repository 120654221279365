import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import moment from 'moment';

import { HttpService } from '../clients/http.service';

import { CreditCard } from '../models/credit-card';
import { BillingCustomer } from '../models/billing-customer';
import { BillingInvoice } from '../models/billing-invoice';
import { Invoice } from '../models/invoice';

@Injectable({ providedIn: 'root' })
export class BillingService {
  constructor(private httpService: HttpService) {}

  public user: any;
  private baseUrl = environment.billingApi;
  hasPaymentMethod = false;

  public async getPaymentMethodsOfCustomer(customerID): Promise<any[]> {
    const paymentMethodsRequest = await this.httpService.get(
      `${this.baseUrl}/customers/${customerID}/payment-methods`
    );
    return paymentMethodsRequest.data;
  }

  public async getPaymentMethodOfCustomer(
    customerId: string,
    paymentMethodId: string
  ): Promise<any> {
    const paymentMethodsRequest = await this.httpService.get(
      `${this.baseUrl}/customers/${customerId}/payment-methods/${paymentMethodId}`
    );
    return paymentMethodsRequest.data;
  }

  public async createPaymentMethodForCustomer(
    payCard: CreditCard,
    customer: BillingCustomer
  ): Promise<any> {
    const reqData = {
      cvv: payCard.cvc,
      holder_first_name: customer.contact_first_name,
      holder_last_name: customer.contact_last_name,
      holder_email: customer.contact_email,
      holder_phone: customer.contact_phone,
      card_number: payCard.cardNumber.replace(/\s/g, ''),
      expiry_year: payCard.expYear,
      expiry_month: payCard.expMonth,
      country_code: customer.billing_address?.country_code,
      primary: true,
    };
    const url = `${this.baseUrl}/customers/${customer.id}/payment-methods`;
    return await this.httpService.post(url, reqData);
  }

  public async init3DSecure(
    customerId: string,
    paymentMethodId: string
  ): Promise<any> {
    const url = `${this.baseUrl}/customers/${customerId}/payment-methods/${paymentMethodId}/3d-secure`;
    return await this.httpService.post(url, {});
  }

  public async confirm3DSecure(
    customerId: string,
    paymentMethodId: string,
    threeDSecureId: string
  ): Promise<any> {
    const url = `${this.baseUrl}/customers/${customerId}/payment-methods/${paymentMethodId}/3d-secure/${threeDSecureId}/complete`;
    return await this.httpService.get(url);
  }

  public async initPaymentMethodAuthChargeVerification(
    customerId: string,
    paymentMethodId: string,
  ): Promise<any> {
    const url = `${this.baseUrl}/customers/${customerId}/payment-methods/`
     + `${paymentMethodId}/auth-charge-verification`;
    return await this.httpService.post(url, {});
  }

  public async deletePaymentMethodForCustomer(
    paymentMethod,
    customerID: string
  ): Promise<any> {
    return this.httpService.delete(
      `${this.baseUrl}/customers/${customerID}/payment-methods` +
        `/${paymentMethod.id}?is_key=true`
    );
  }

  public async setAsDefault(paymentMethod): Promise<any> {
    const url =
      `${this.baseUrl}/payment-methods` + `/${paymentMethod.id}/set_default`;
    const result = await this.httpService.patch(url, {});
    return result;
  }

  public async createCustomer(
    firstname,
    lastname,
    email,
    externalID
  ): Promise<any> {
    const now = new Date();
    const reqDef = {
      first_name: firstname, // TODO: Update this for display purposes
      last_name: lastname,
      email,
      next_call: now.getFullYear() + '-' + now.getMonth() + '-' + now.getDay(),
      external_customer_uid: externalID,
    };
    return await this.httpService.post(`${this.baseUrl}/customers`, reqDef);
  }

  public async getCustomerWithID(customerID): Promise<any> {
    try {
      const resp = await this.httpService.get(
        `${this.baseUrl}/customers/${customerID}?is_key=false`
      );
      return resp.data;
    } catch (err) {
      if (err.code === 404) {
        return undefined;
      }
    }
  }

  public async getCustomerWithKey(customerKey): Promise<any> {
    try {
      const resp = await this.httpService.get(
        `${this.baseUrl}/customers/${customerKey}`
      );
      return resp.data;
    } catch (err) {
      if (err.code === 404) {
        return undefined;
      }
    }
  }

  public async getInvoices(customerKey): Promise<any> {
    try {
      const resp = await this.httpService.get(
        `${this.baseUrl}/customers/${customerKey}/invoices`
      );
      return resp.data;
    } catch (err) {
      if (err.code === 404) {
        return undefined;
      }
    }
  }

  async createInvoicePayment(invoice: BillingInvoice): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'create_invoice_payment',
      args: {
        invoice_id: invoice.id,
      },
    });
  }

  async collectInvoicePayment(invoice: BillingInvoice): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'collect_invoice_payment',
      args: {
        invoice_id: invoice.id,
      },
    });
  }

  createAccountingCustomer(customer: BillingCustomer): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'create_accounting_customer',
      args: {
        customer_id: customer.id,
      },
    });
  }

  createPaymentCustomer(customer: BillingCustomer): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'create_payment_customer',
      args: {
        customer_id: customer.id,
      },
    });
  }

  async createInvoice(billingCustomer: BillingCustomer, billingPeriod: string) {
    return this.httpService.post(`${environment.apiBaseUrl}/admin/invoices`, {
      customerKey: billingCustomer.id,
      billingPeriod,
    });
  }

  postBillingInvoice(invoice: Invoice): Promise<any> {
    const billing_period = moment(invoice.billingPeriod, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD');

    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'create_invoice',
      args: {
        customer_id: invoice.customerKey,
        billing_period,
      },
    });
  }

  creditBillingInvoice(billingInvoice: BillingInvoice): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'credit_invoice',
      args: {
        invoice_id: billingInvoice.id,
        customer_id: billingInvoice.customer_id,
      },
    });
  }

  uploadBillingInvoice(billingInvoice: BillingInvoice): Promise<any> {
    return this.httpService.post(`${this.baseUrl}/tasks`, {
      name: 'upload_invoice',
      args: {
        invoice_id: billingInvoice.id,
      },
    });
  }
}
