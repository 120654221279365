import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../../clients/http.service';
import { MarketplaceCapsule } from '../../models/marketplace-capsule';
import { MarketplaceCategory } from '../../models/marketplace-category';

@Injectable()
export class MarketplaceService {
  private baseUrl = environment.apiBaseUrl + '/marketplace';

  private capsules: MarketplaceCapsule[] = [];

  constructor(private httpService: HttpService) {}

  getCategories(): MarketplaceCategory[] {
    return [
      new MarketplaceCategory('auth', 'Auth'),
      new MarketplaceCategory('data', 'Data'),
      new MarketplaceCategory('storage', 'Storage'),
    ];
  }

  async getCapsules(categories: string[] = []): Promise<MarketplaceCapsule[]> {
    let query = '';
    if (categories.length > 0) {
      query = `?category=${categories.join('&')}`;
    }

    const res = await this.httpService.get(`${this.baseUrl}${query}`);
    this.capsules = res.data;
    return this.capsules;
  }

  async deploy(deploymentRequest: any): Promise<any> {
    return this.httpService.post(
      `${this.baseUrl}/deployment`,
      deploymentRequest
    );
  }
}
