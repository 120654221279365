import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from './../http.service';
import { Repo, Cluster } from '../../models';

export interface Branch {
  name: string;
  commit: {
    sha: string;
    url: string;
  };
}

interface ProviderDataKey {
  installationKey: string;
  repoKey: string;
}
interface ProviderDataId {
  installationId: string;
  repoId: string;
}

export interface CapsuleApiRepoPayload {
  branch: string;
  isPrivate: boolean;
  provider: string;
  url: string;
  providerData: ProviderDataId | ProviderDataKey;
}

export interface RepoInfo {
  repo: Repo;
  branch: string;
  cluster: Cluster;
}

@Injectable({
  providedIn: 'root',
})
export class RepoService {
  constructor(private httpService: HttpService) {}

  async getRepoBranches(repoId: string): Promise<Branch[]> {
    const result = await this.httpService.get(
      `${environment.apiBaseUrl}/git-repo/${repoId}/branches`
    );
    return result.data;
  }

  async getRepoLanguages(repoId: string): Promise<any> {
    const result = await this.httpService.get(
      `${environment.apiBaseUrl}/git-repo/${repoId}/languages`
    );
    return this.sortBranches(result.data);
  }

  getCapsuleApiRepoPayload(info: RepoInfo): CapsuleApiRepoPayload {
    const repo = info.repo;
    const branch = info.branch;

    const res: CapsuleApiRepoPayload = {
      branch: branch,
      isPrivate: repo.isPrivate,
      provider: repo.gitInstallation.provider,
      url: repo.url,
      providerData: {
        installationKey: String(repo.gitInstallation.remoteKey),
        repoKey: String(repo.remoteKey),
      },
    };

    if (this.isOldClusterApi(info.cluster)) {
      res.providerData = {
        installationId: String(repo.gitInstallation.remoteKey),
        repoId: String(repo.remoteKey),
      };
    }
    return res;
  }

  private isOldClusterApi(cluster: Cluster): boolean {
    return cluster.capsuleApiVersion == '0.3.390-hotfix';
  }

  private sortBranches(branches: Branch[]): Branch[] {
    const mainBranchNames = [
      'master',
      'main',
      'production',
      'prod',
      'primary',
      'deploy',
    ];
    return branches.sort((branch1, branch2): number => {
      const includes1 = mainBranchNames.includes(branch1.name.toLowerCase());
      const includes2 = mainBranchNames.includes(branch2.name.toLowerCase());
      return includes1 ? -1 : includes2 ? 1 : 0;
    });
  }
}
