import { User } from '@appstrax/services/auth';
import { Cluster } from './../../models/cluster';
import { createAction, props } from '@ngrx/store';
import { UserProfile } from '../../models/user-profile';
import { PhoneNumber } from './../../models/phone-number';
import { BillingCustomer } from 'src/app/core/models/billing-customer';
import { BillingPaymentMethod } from 'src/app/core/models/billing-payment-method';
import { CustomerUsage } from '../../models/customer-usage';
import { Invoice } from '../../models/invoice';
import { BillingProduct } from '../../models/billing-product';
import { Capsule, Space, Team } from '../../models';
import { Plan } from '../../models/plan';
import { BillingLine } from '../../models/billing-line';
import { BillingInvoice } from '../../models/billing-invoice';
import { BillingTask } from "../../models/billing-task";

export const fetchUsers = createAction('[Admin] Fetch Users Initiated');
export const fetchUsersSuccess = createAction(
  '[Admin] Fetch Users Success',
  props<{ users: UserProfile[] }>()
);
export const fetchUsersFailed = createAction(
  '[Admin] Fetch Users Failed',
  props<{ error: Error }>()
);

export const fetchBillingCustomers = createAction(
  '[Admin] Fetch BillingCustomers Initiated'
);
export const fetchBillingCustomersSuccess = createAction(
  '[Admin] Fetch BillingCustomers Success',
  props<{ billingCustomers: BillingCustomer[] }>()
);
export const fetchBillingCustomersFailed = createAction(
  '[Admin] Fetch BillingCustomers Failed',
  props<{ error: Error }>()
);

export const fetchUsage = createAction('[Admin] Fetch Usage Initiated');
export const fetchUsageSuccess = createAction(
  '[Admin] Fetch Usage Success',
  props<{ usage: CustomerUsage }>()
);
export const fetchUsageFailed = createAction(
  '[Admin] Fetch Usage Failed',
  props<{ error: Error }>()
);

export const fetchCustomerUsage = createAction(
  '[Admin] Fetch Customer Usage Initiated',
  props<{ userId: string }>()
);
export const fetchCustomerUsageSuccess = createAction(
  '[Admin] Fetch Customer Usage Success',
  props<{ userId: string; usage: CustomerUsage }>()
);
export const fetchCustomerUsageFailed = createAction(
  '[Admin] Fetch Customer Usage Failed',
  props<{ error: Error }>()
);

export const fetchInvoices = createAction('[Admin] Fetch Invoices Initiated');
export const fetchInvoicesSuccess = createAction(
  '[Admin] Fetch Invoices Success',
  props<{ invoices: Invoice[] }>()
);
export const fetchInvoicesFailed = createAction(
  '[Admin] Fetch Invoices Failed',
  props<{ error: Error }>()
);

export const fetchBillingLines = createAction(
  '[Admin] Fetch Billing Lines Initiated'
);
export const fetchBillingLinesSuccess = createAction(
  '[Admin] Fetch Billing Lines Success',
  props<{ billingLines: BillingLine[] }>()
);
export const fetchBillingLinesFailed = createAction(
  '[Admin] Fetch Billing Lines Failed',
  props<{ error: Error }>()
);

export const fetchBillingInvoices = createAction(
  '[Admin] Fetch Billing Invoices Initiated'
);
export const fetchBillingInvoicesSuccess = createAction(
  '[Admin] Fetch Billing Invoices Success',
  props<{ billingInvoices: BillingInvoice[] }>()
);
export const fetchBillingInvoicesFailed = createAction(
  '[Admin] Fetch Billing Invoices Failed',
  props<{ error: Error }>()
);


export const fetchBillingTasks = createAction(
  '[Admin] Fetch Billing Tasks Initiated'
);
export const fetchBillingTasksSuccess = createAction(
  '[Admin] Fetch Billing Tasks Success',
  props<{ billingTasks: BillingTask[] }>()
);
export const fetchBillingTasksFailed = createAction(
  '[Admin] Fetch Billing Tasks Failed',
  props<{ error: Error }>()
);
export const dispatchBillingTask = createAction(
  '[Admin] Dispatch billing task Initiated',
  props<{ task: string, args: string[] }>()
);


export const setCurrentBillingInvoice = createAction(
  '[Admin] Fetch individual Billing Invoice Initiated',
  props<{ invoiceId: string }>()
);
export const fetchBillingInvoice = createAction(
  '[Admin] Fetch individual Billing Invoice Initiated',
  props<{ invoiceId: string }>()
);
export const fetchBillingInvoiceSuccess = createAction(
  '[Admin] Fetch individual Billing Invoice Success',
  props<{ billingInvoice: BillingInvoice }>()
);
export const fetchBillingInvoiceFailed = createAction(
  '[Admin] Fetch individual Billing Invoice Failed',
  props<{ error: Error }>()
);

export const fetchProducts = createAction('[Admin] Fetch Products Initiated');
export const fetchProductsSuccess = createAction(
  '[Admin] Fetch Products Success',
  props<{ products: BillingProduct[] }>()
);
export const fetchProductsFailed = createAction(
  '[Admin] Fetch Products Failed',
  props<{ error: Error }>()
);

export const fetchPlans = createAction('[Admin] Fetch Plans Initiated');
export const fetchPlansSuccess = createAction(
  '[Admin] Fetch Plans Success',
  props<{ plans: Plan[] }>()
);
export const fetchPlansFailed = createAction(
  '[Admin] Fetch Plans Failed',
  props<{ error: Error }>()
);

export const fetchTeams = createAction('[Admin] Fetch Teams Initiated');
export const fetchTeamsSuccess = createAction(
  '[Admin] Fetch Teams Success',
  props<{ teams: Team[] }>()
);
export const fetchTeamsFailed = createAction(
  '[Admin] Fetch Teams Failed',
  props<{ error: Error }>()
);

export const fetchSpaces = createAction('[Admin] Fetch Spaces Initiated');
export const fetchSpacesSuccess = createAction(
  '[Admin] Fetch Spaces Success',
  props<{ spaces: Space[] }>()
);
export const fetchSpacesFailed = createAction(
  '[Admin] Fetch Spaces Failed',
  props<{ error: Error }>()
);


export const fetchCapsules = createAction('[Admin] Fetch Capsules Initiated');
export const fetchCapsulesSuccess = createAction(
  '[Admin] Fetch Capsules Success',
  props<{ capsules: Capsule[] }>()
);
export const fetchCapsulesFailed = createAction(
  '[Admin] Fetch Capsules Failed',
  props<{ error: Error }>()
);

export const fetchSpacesCapsules = createAction(
  '[Admin] Fetch Spaces Capsules Initiated'
);
export const fetchSpacesCapsulesSuccess = createAction(
  '[Admin] Fetch Spaces Capsules Success',
  props<{ space: Space; capsules: Capsule[] }>()
);
export const fetchSpacesCapsulesFailed = createAction(
  '[Admin] Fetch Spaces Capsules Failed',
  props<{ error: Error }>()
);

export const fetchPhoneNumbers = createAction(
  '[Admin] Fetch Phone Numbers Initiated'
);
export const fetchPhoneNumbersSuccess = createAction(
  '[Admin] Fetch Phone Numbers Success',
  props<{ phoneNumbers: PhoneNumber[] }>()
);
export const fetchPhoneNumbersFailed = createAction(
  '[Admin] Fetch Phone Numbers Failed',
  props<{ error: Error }>()
);

export const fetchClusters = createAction(
  '[Admin] Fetch clusters initiated'
);

export const fetchClustersSuccess = createAction(
  '[Admin] Fetch clusters succeeded',
  props<{ clusters: Cluster[]}>()
);


export const fetchClustersFailed = createAction(
  '[Admin] Fetch clusters failed',
  props<{ error: Error }>(),
);

// export const fetchAuthUser = createAction(
//   '[Auth API] Fetch Auth User Initiated',
//   props<{ userId: string }>()
// );
// export const fetchAuthUserSuccess = createAction(
//   '[Auth API] Fetch Auth User Success',
//   props<{ userId: string }>()
// );
// export const fetchAuthUserFailed = createAction(
//   '[Auth API] Fetch Auth User Failed',
//   props<{ error: Error }>()
// );

// export const fetchUser = createAction(
//   '[Auth API] Fetch User Initiated',
//   props<{ userId: string }>()
// );
// export const fetchUserSuccess = createAction(
//   '[Auth API] Fetch User Success',
//   props<{ users: User[] }>()
// );
// export const fetchUserFailed = createAction(
//   '[Auth API] Fetch User Failed',
//   props<{ error: Error }>()
// );

// export const fetchUserTeams = createAction(
//   '[Team API] Fetch User Teams Initiated',
//   props<{ userId: string }>()
// );
// export const fetchUserTeamsSuccess = createAction(
//   '[Team API] Fetch User Teams Success',
//   props<{ userTeams: any[] }>() // TODO: Use site model instead of api
// );
// export const fetchUserTeamsFailed = createAction(
//   '[Team API] Fetch User Teams Failed',
//   props<{ error: Error }>()
// );

// export const fetchUserClusters = createAction(
//   '[Cluster API] Fetch User Clusters Initiated',
//   props<{ userId: string }>()
// );
// export const fetchUserClustersSuccess = createAction(
//   '[Cluster API] Fetch User Clusters Success',
//   props<{ clusters: Cluster[] }>()
// );
// export const fetchUserClustersFailed = createAction(
//   '[Cluster API] Fetch User Clusters Failed',
//   props<{ error: Error }>()
// );

// export const fetchUserCustomer = createAction(
//   '[Billing API] Fetch User Customer Initiated',
//   props<{ userId: string }>()
// );
// export const fetchUserCustomerSuccess = createAction(
//   '[Billing API] Fetch User Customer Success',
//   props<{ customer: BillingCustomer }>()
// );
// export const fetchUserCustomerFailed = createAction(
//   '[Billing API] Fetch User Customer Failed',
//   props<{ error: Error }>()
// );

// export const fetchCustomerPaymentMethods = createAction(
//   '[Billing API] Fetch Customer Payment Methods Initiated',
//   props<{ customerId: string }>()
// );
// export const fetchCustomerPaymentMethodsSuccess = createAction(
//   '[Billing API] Fetch Customer Payment Methods Success',
//   props<{ paymentMethods: BillingPaymentMethod[] }>()
// );
// export const fetchCustomerPaymentMethodsFailed = createAction(
//   '[Billing API] Fetch Customer Payment Methods Failed',
//   props<{ error: Error }>()
// );
