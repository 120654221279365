import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { EnvVar } from '../../models';

@Component({
  selector: 'app-evn-var-editor',
  templateUrl: './evn-var-editor.component.html',
  styleUrls: ['./evn-var-editor.component.scss'],
})
export class EnvVarEditorComponent implements OnChanges {
  @Input() envVars: EnvVar[];

  public code = '';
  public editorOptions = {
    language: 'dotenv',
    minimap: { enabled: false },
    wordWrap: 'off',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['envVars']) return;

    const systemVars = this.envVars.filter((c) => c.setBy === 'System');
    const userVars = this.envVars.filter((c) => c.setBy === 'User');

    const vars = systemVars.map((config) => `#${config.key}:${config.value}`);
    vars.push(...userVars.map((config) => `${config.key}=${config.value}`));
    this.code = vars.join('\n');
  }

  public getEnvironmentVariables(): EnvVar[] {
    let envVars: EnvVar[] = [];
    const lines = this.code.split('\n');

    for (let line of lines) {
      line = line.trim();
      if (!line || line[0] == '#') continue;

      const equalsIndex = line.indexOf('=', 0);
      if (equalsIndex > 0) {
        const key = line.substring(0, equalsIndex);
        const value = line.substring(equalsIndex + 1);

        envVars.push({
          key: key.trim(),
          value: value.trim(),
          setBy: 'User',
        });
      }
    }

    return envVars;
  }

  // private isValidEnvVar(key): boolean {
  //   // ENV keys need to conform to this regex pattern
  //   return /^[a-zA-Z][0-9a-zA-Z_\-.]*$/.test(key);
  // }
}
