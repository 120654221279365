import { Action, createReducer, on } from '@ngrx/store';
import { initialState, InvoiceState } from './invoice.state';

import * as UserActions from '../user/user.actions';
import * as InvoiceActions from './invoice.actions';
import { BillingInvoice } from '../../models/billing-invoice';

const invoiceReducer = createReducer(
  initialState,

  on(InvoiceActions.fetchInvoices, (state) => ({
    ...state,
    loading: true,
  })),
  on(InvoiceActions.fetchInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    invoices,
    loading: false,
    loadedAt: new Date(),
  })),
  on(InvoiceActions.fetchInvoicesFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(InvoiceActions.payInvoiceSuccess, (state, { invoice, billingTask }) => {
    console.log(billingTask);
    const invoices: BillingInvoice[] = state.invoices.map(
      (i: BillingInvoice) => {
        let paymentTask = i.paymentTask;
        if (invoice.id === i.id) {
          paymentTask = billingTask;
        }

        return {
          ...i,
          paymentTask,
        };
      }
    );

    return {
      ...state,
      invoices,
      loading: false,
    };
  }),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    invoices: [],
    loadedAt: null,
    loading: false,
    saving: false,
    error: null,
  }))
);

export function reducer(state: InvoiceState | undefined, action: Action) {
  return invoiceReducer(state, action);
}
