import { Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { Capsule, Product, Space } from '../../models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpService: HttpService) {}

  async getProductsByCapsuleTypeId(
    capsuleType: string,
    space: Space
  ): Promise<Product[]> {
    const response = await this.httpService.get(
      `${environment.apiBaseUrl}/products/?spaceId=${space.id}&capsuleType=${capsuleType}`
    );
    if (!response.data && response.data.length === 0) {
      return [];
    }
    return response.data;

    // return response.data.filter(
    //   (product) => product.capsuleType === capsuleType
    // );
  }

  // async getCurrentCapsule() {
  //   return;
  // }

  async updateProduct(product: Product, space: Space, capsule: Capsule): Promise<any> {
    const data = {
      space,
      productKey: product.productKey
    };
    const response = await this.httpService.put(
      `${environment.apiBaseUrl}/capsules/${capsule.id}/product`,
      data
    );
    return response.data;
  }
}
