import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'confirm-danger-modal.component.html',
})
export class ConfirmDangerModalComponent {
  public options: ConfirmModalOptions;
  public hide: boolean = false;

  public input = '';
  public error = '';

  constructor(private bsModalRef: BsModalRef) {}

  onConfirm(): void {
    if (this.input !== 'confirm') {
      this.error = 'Input does not match.';
      this.input = '';
      return;
    }
    this.options.confirm();
    this.close();
  }

  onReject(): void {
    if (this.options.reject) {
      this.options.reject();
    }
    this.close();
  }

  close(): void {
    this.bsModalRef.hide();
  }
}

export class ConfirmModalOptions {
  title: string;
  message: string;
  confirm: () => void;
  reject?: () => void;
}
