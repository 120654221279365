import { BaseState } from '../base.state';
import { Cluster } from './../../models/cluster';
import { UserProfile, Team, Capsule } from 'src/app/core/models';
import { PhoneNumber } from './../../models/phone-number';
import { BillingCustomer } from '../../models/billing-customer';
import { Invoice } from '../../models/invoice';
import { BillingProduct } from '../../models/billing-product';
import { CustomerUsage } from '../../models/customer-usage';
import { Plan } from '../../models/plan';
import { Space } from '../../../core/models/space';
import { BillingInvoice } from '../../models/billing-invoice';
import { BillingTask } from '../../models/billing-task';

export interface AdminState extends BaseState {
  billingCustomers: BillingCustomer[];
  billingCustomersById: {};
  currentBillingInvoice: BillingInvoice;
  billingTasks: BillingTask[];
  users: UserProfile[];
  usersById: {};
  teams: Team[];
  spaces: Space[];
  capsules: Capsule[];
  invoices: Invoice[];
  products: BillingProduct[];
  phoneNumbers: PhoneNumber[];
  usage: CustomerUsage;
  customerUsage: CustomerUsage[];
  plans: Plan[];
  clusters: Cluster[];
}

export const initialState: AdminState = {
  billingCustomers: [],
  billingCustomersById: {},
  currentBillingInvoice: null,
  billingTasks: [],

  users: [],
  usersById: {},

  teams: [],
  spaces: [],
  capsules: [],
  invoices: [],
  products: [],
  phoneNumbers: [],

  usage: {
    userId: null,
    clusters: [],
    teams: [],
    clustersTotal: 0,
    teamsTotal: 0,
    total: 0,
  },
  customerUsage: [],

  plans: [],
  clusters: [],
};
