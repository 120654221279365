import { createAction, props } from '@ngrx/store';
import { BillingCustomer } from '../../models';
import { BillingInvoice } from '../../models';
import { BillingTask } from '../../models';

export const setError = createAction(
  '[Invoice] Set Error',
  props<{ error: Error }>()
);

export const fetchInvoices = createAction(
  '[Invoice] Fetch Invoices Initiated',
  props<{ customer: BillingCustomer }>()
);
export const fetchInvoicesSuccess = createAction(
  '[Invoice] Fetch Invoices Success',
  props<{ invoices: BillingInvoice[] }>()
);
export const fetchInvoicesFailed = createAction(
  '[Invoice] Fetch Invoices Failed',
  props<{ error: Error }>()
);

export const payInvoice = createAction(
  '[Invoice] Pay Invoice Initiated',
  props<{ invoice: BillingInvoice; customer: BillingCustomer }>()
);
export const payInvoiceSuccess = createAction(
  '[Invoice] Pay Invoice Success',
  props<{ invoice: BillingInvoice; billingTask: BillingTask }>()
);
export const payInvoiceFailed = createAction(
  '[Invoice] Pay Invoice Failed',
  props<{ error: Error }>()
);
